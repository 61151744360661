import { useState } from 'react';
import './style/style.css';
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import Modal from 'react-bootstrap/Modal';

const textContent = {
  en: {
    BlockA: 'Block A',
    BlockB: 'Block B',
    BlockC: 'Block C',
    BlockD: 'Block D',
    BlockE: 'Block E',
    MainAttractionsHeading: 'Main Attractions',
    exhibits: {
      
      BlockA: [
        {
          ReadMore: 'Read More',
          title: 'Reception',
          description: 'Behold the imposing Sudarshana Chakra.  This 8-ton Chakra is suspended here from the ceiling to enchant you with its cosmic presence. Crafted with exquisite details by skilled artisans from West Bengal, it is adorned with thousands of meticulously placed stones and gems, offering you a glimpse of its celestial power. Complementing this spectacle is a backdrop of cascading water that projects scenes from the Mahabharata, enhancing the overall experience. ',
          image: '/images/block-a/block-a-image-1.jpg',
        },
        {
          ReadMore: 'Read More',
          title: 'Birth of an Epic',
          description: 'The briefing area features a remarkable projection depicting a dialogue  between Ved Vyasa and Lord Brahma, focusing on the collection of Vedic knowledge and its dissemination through the creation of the invaluable epic, the Mahabharata.  In this divine discourse, Lord Ganesha assumes a pivotal role as the scribe, transcribing the epic as narrated by Ved Vyasa. ',
          image: '/images/block-a/block-a-image-2.jpg',
        },
        {
          ReadMore: 'Read More',
          title: '48 Kos ',
          description: 'This installation captures the essence of the 48 kos yatra of the Kurukshetra region, encompassing 18 temples and 4 Yakshas within the modern boundaries of Haryana. This area is designed to offer a comprehensive experience, combining elements such as an installation, a projection film, two murals depicting Yaksha and Yakshini crafted by artists from Kolkata, and an informative kiosk for you to gain a profound understanding of the narrative. ',
          image: '/images/block-a/block-a-image-3.jpg',
        },
      ],
      BlockB: [
        {
          ReadMore: 'Read More',
          title: 'Yagyaseni',
          description: 'Here in this installation, the pivotal moment of Draupadis birth, emerging from the fire, is brought to life through a hyper-realistic sculpture. Draupadis emergence from the fire symbolizes her purity and divine origin. Here to create a realistic picture of this scene the artists from Shantiniketan, West Bengal meticulously designed the base of this installation.',
          image: '/images/block-b/block-b-image-1.jpg',
        },
        {
          ReadMore: 'Read More',
          title: 'Lakshagraha',
          description: 'Here you witness the gripping portrayal of the Lakshagraha inferno on a screen, where Duryodhana, alongside his maternal uncle Shakuni, plotted the demise of the Pandavas and their mother within the "House of Lac ," a structure designed by the Kauravas to be easily ignited. However, the Pandavas, aided by Vidura, their sagacious uncle, and Krishna, their loyal friend and charioteer, foiled the scheme of Kauravas by tunnelling their way to safety. This failed assassination attempt hints at the impending conflict between the Pandavas and Kauravas, foreshadowing the epic Kurukshetra war. ',
          image: '/images/block-b/block-b-image-2.jpg',
        },
        {
          ReadMore: 'Read More',
          title: 'Khandavprasth',
          description: 'The tale unfolds with Lord Agni assuming the guise of a Brahmana, imploring Arjuna and Krishna for their aid in burning the Khandava forest to quell his hunger. Home to various tribes, including the Nagas, the forest is burnt, sparing only a snake named Ashwasena , who was the son of Takshaka, the king of the snakes or Nagaraja. Takshakas wife sacrifices herself to protect Ashwasena. In retaliation for her demise, Takshaka slew Parikshit. Here 2 hyper realistic  sculptures are put together by the Kolkata artists to narrate this entire scene. One is of Arjuna who is aiming at the Khandava forest with his bow and the other one is of Parikshit meditating on lord Vishnu before getting killed by the bite of Takshaka.  ',
          image: '/images/block-b/block-b-image-3.jpg',
        },
      ],
      BlockC: [
        {
          ReadMore: 'Read More',
          title: '9 highlights of war ',
          description: "Nine LED screens introduce the lesser-known narratives of the Mahabharata, which, significantly impacted the overall story. The audience moves from the lesser known  story of Barbaric , to Raja Shalya, Arjunas chariot and many others. ",
          image: '/images/block-c/block-c-image-1.jpg',
        },
        {
          ReadMore: 'Read More',
          title: 'BHEESHMA’S BED OF ARROWS',
          description: 'This zone enthrals you with a spellbinding Kinetic installation of Bheeshma shayya with a hyper-realistic sculpture of Bheeshma being on the shaiya of arrows and hundreds of specially crafted arrows dynamically moving through a kinetic installation. ',
          image: '/images/block-c/block-c-image-2.jpg',
        },
        {
          ReadMore: 'Read More',
          title: 'ANCIENT ARTIFACTS',
          description: 'Here the viewer gets the privilege of seeing sculptures from the Gurjara-Pratihara period, dating back to the 8th and 9th centuries CE which have never been unveiled before.',
          image: '/images/block-c/block-c-image-3.jpg',
        },
      ],
      BlockD: [
        {
          ReadMore: 'Read More',
          title: 'Ashwatthama',
          description: "Before you stands a colossal and highly realistic statue of Ashwatthama. On the final day of the Mahabharata war, after the death of Drona and after sunset, a furious Ashwatthama, driven by rage, massacred the entire lineage of the Pandavas while their army was in deep slumber. He even attempted to kill Abhimanyu's unborn child. This unethical act was a blatant violation of the rules of war. ",
          image: '/images/block-d/block-d-image-1.jpg',
        },
        {
          ReadMore: 'Read More',
          title: 'Dhritirashtra’s vengeance',
          description: 'A projection on a holographic screen in the next zone unfolds a heartful tale of revenge. Shown by a meticulously recreated replica of Dhritarashtras court, where the grief-stricken king mourning the loss of his sons, seeks vengeance against Bhima.',
          image: '/images/block-d/block-d-image-2.jpg',
        },
        {
          ReadMore: 'Read More',
          title: 'Road to Heaven',
          description: 'This is the path that leads to the very lap of the majestic Himalayas, a route once traversed by the holy feet of the Pandavas. After the epic battle of Kurukshetra and years of ruling Hastinapur, the Pandavas, having fulfilled all their dharma duties, decided to renounce their worldly lives and embark on a journey to the heavens. They set out towards the Himalayas, believed to be the gateway to the divine realm. Along the way, one by one, Draupadi and the five brothers — Bhima, Arjuna, Nakul, Sahadeva, and Yudhishthira — met their end. Yet, Yudhishthira remained steadfast, surviving the journey until the very end. It was his unwavering commitment to truth and dharma that allowed him to reach the heavens alive. ',
          image: '/images/block-d/block-d-image-3.jpg',
        },
      ],
      BlockE: [
        {
          ReadMore: 'Read More',
          title: 'ARJUNA’S CHARIOT',
          description: "Get enlightened by the wisdom of the Gita by sitting in that same chariot where Arjuna sat with Krishna as his charioteer. A chariot with four majestic hyper-realistic horses beautifully recreates that divine moment.  ",
          image: '/images/block-e/block-e-image-1.jpg',
        },
        {
          ReadMore: 'Read More',
          title: 'The wisdom of Gita',
          description: "These Gita Pods are an unparalleled treasure of wisdom, where you can find answers to your life’s questions in the revered verses of the Gita. ",
          image: '/images/block-e/block-e-image-2.jpg',
        },
        {
          ReadMore: 'Read More',
          title: 'The Divine Resonance of Krishna ',
          description: 'A massive and beautiful installation of thousands of bells swaying in the air. The convergence of these bells creates a divine image, where Lord Krishna is depicted playing his flute. The sweetness of the bell sounds and the image of Krishna playing the flute embody cosmic harmony, divine love, and inner peace, all of which have a positive impact on mental and physical well-being.',
          image: '/images/block-e/block-e-image-3.jpg',
        },
      ],
      
    },
  },
  hi: {    
    BlockA: 'ब्लॉक - ए',
    BlockB: 'ब्लॉक - बी',
    BlockC: 'ब्लॉक - सी',
    BlockD: 'ब्लॉक - डी',
    BlockE: 'ब्लॉक - ई',
    MainAttractionsHeading: 'मुख्य आकर्षण',
    exhibits: {
      BlockA: [
        {
          ReadMore: 'और पढ़ें',
          title: 'स्वागत कक्ष',
          description: 'यहाँ आपके समक्ष है भव्य सदुर्शन चक्र।  अपनी ब्रह्मांडीय उपस्थिति से आपको आश्चर्यचकित करने के लिए आठ टन भारी इस चक्र को यहाँ छत से निलंबित किया गया है। पश्चिम बंगाल के कुशल कारीगरों द्वारा उत्कृष्ट विवरणों के साथ निर्मित यह हज़ारों  सावधानीपर्वूक रखे गए रत्नों से सुशोभित है, जो आपको इसकी दिव्य शक्ति की एक झलक प्रदान करता है।  । इस दृश्य में बहते पानी की पृष्ठभूमि भी शामिल है, जो महाभारत के दृश्यों को पेश करता है, जिस से समग्र अनभुव बढ़ जाता है।',
          image: '/images/block-a/block-a-image-1.jpg',
        },
        {
          ReadMore: 'और पढ़ें',
          title: 'प्राचीन महाभारत',
          description: "और इसी मानचित्र के साथ 700 साल प्राचीन महाभारत की प्रति को आसन पर सुंदर रूप से सजाया गया है।  इसी के साथ स्पिट्ज़र लिपि में लिखे दस्तावेज़ों की प्रतिकृतियां है जो वर्तमान में ब्रिटिश सग्रंहालय में रखी गई है।  ",
          image: '/images/block-a/block-a-image-2.jpg',
        },
        {
          ReadMore: 'और पढ़ें',
          title: '48 कोस',
          description: "यह स्थापना कुरुक्षेत्र क्षेत्र की 48 कोस यात्रा के सार को दर्शाती है, जिसमें हरियाणा की आधनिुनिक सीमाओं के भीतर 18 मंदिर  और 4 यक्ष शामिल हैं। इस क्षेत्र को एक व्यापक अनभुव प्रदान करने के लिए बनाया गया है, जिसमें एक त्रिआयामी चित्र, एक प्रोजेक्शन फिल्म, कोलकाता के कलाकारों द्वारा तैयार किए गए यक्ष और यक्षिनी को दर्शाने वाले दो भित्ति चित्र और कथा की गहरी समझ प्राप्त करने के लिए एक सूचनात्मक कियोस्क जसै तत्वों का संयोजन  किया गया है।",
          image: '/images/block-a/block-a-image-3.jpg',
        },
      ],
      BlockB: [
        {
          ReadMore: 'और पढ़ें',
          title: 'यज्ञसेनी',
          description: "यहाँ इस स्थापना में, द्रौपदी के जन्म के महत्वपर्णू क्षण, आग से उभरते हुए, एक अति -यथार्थवादी माध्यम से जीवंत किया गया है। द्रौपदी  का आग से उभरना उनकी शुद्धता और दिव्य उत्पत्ति का प्रतीक है। यहाँ इस दृश्य की एक यथार्थवादी तस्वीर बनाने के लिए पशचिम बंगाल के शांतिनिकेतन के कलाकार ने इस स्थापना के आधार को सावधानीपर्वूक बनाया है।  ",
          image: '/images/block-b/block-b-image-1.jpg',
        },
        {
          ReadMore: 'और पढ़ें',
          title: 'लाक्षाग्रह',
          description: "यहाँ आप एक पर्दे पर लाक्षागहृ का चित्रण देख सकते हैं,  जहाँ दुर्योधन ने अपने मामा शकुनि के साथ मिलकर पांडवों और उनकी माँ कुन्ती की मृत्यु की साज़िश  'लाक के घर' के भीतर रची थी, जिसे कौरवो द्वारा आसानी से प्रज्वलित करने के लिए बनाया गया था। हालाँकि, पांडवों ने अपने दरूदर्शी चाचा विदुर और उनके मित्र  श्री कृष्ण की सहायता से सुरंग बनाकर कौरवों की योजना को विफल कर दिया। हत्या का यह असफल प्रयास पांडवों और कौरवों के बीच आसन्न सघर्ष का संकेत और कुरुक्षेत्र युद्ध का पूर्वाभास देता है ।  ",
          image: '/images/block-b/block-b-image-2.jpg',
        },
        {
          ReadMore: 'और पढ़ें',
          title: 'खांडवप्रस्त',
          description: "यह कहानी भगवान अग्नि के ब्राह्मण का भेष धारण करने के साथ प्रारंभ होती है, जब अग्नि देव, अर्जुन और कृष्ण से उनकी भूख को शांत करने के लिए खाण्डव जंगल को जलाने में उनकी सहायता के लिए विनती करते हैं । नागाओं सहित विभिन्न जनजातियों का घर, जंगल को,  जला दिया जाता है।केवल अश्वसेन नाम के एक सांप को छोड़ दिया जाता है, जो सांपों के राजा- नागराज, तक्षक का पुत्र था। अश्वसेन की रक्षा के लिए तक्षक की पत्नी अपना बलिदान देती है। उसकी मृत्यु के प्रतिशोध में, तक्षक परीक्षित की हत्या कर देता है। यहाँ इस पूरे दृश्य का वर्णन करने के लिए कोलकाता के कलाकारों द्वारा दो अति- यथार्थवादी मूर्तियों को एक साथ रखा गया है। एक अर्जुन का है जो अपने धनुष से खांडव वन को निशाना बना रहें है और दूसरा परीक्षित का है जो तक्षक के काटने से मारे जाने से पहले भगवन विष्णु का ध्यान कर रहें हैं। ",
          image: '/images/block-b/block-b-image-3.jpg',
        },
      ],
      BlockC: [
        {
          ReadMore: 'और पढ़ें',
          title: 'युद्ध के 9 प्रमुख दृष्टांत',
          description: "नौ एलईडी स्क्रीन महाभारत की उन कम प्रचलित कथाओं का परिचय कराती हैं, जिन्होंने सम्पूर्ण महाकाव्य की संरचना पर गहन प्रभाव डाला। दर्शक बर्बरिक की अप्रसिद्ध कथा से लेकर राजा शल्य, अर्जुन के रथ और अन्य अनेक रोचक प्रसंगों की ओर अग्रसर होते हैं",
          image: '/images/block-c/block-c-image-1.jpg',
        },
        {
          ReadMore: 'और पढ़ें',
          title: 'भीष्म शरशय्या',
          description: "यह  क्षेत्र आपको भीष्म-शैया की एक मंत्रमुग्ध कर देने वाली गतिशील स्थापना से रोमांचित कर देता है, जहाँ भीष्म की अत्यंत यथार्थवादी मूर्ति उन्हें बाणों की शैया पर स्थित दर्शाती है। सैकड़ों विशेष रूप से निर्मित बाण इस गतिशील स्थापना को अद्वितीय बनाते हैं ",
          image: '/images/block-c/block-c-image-2.jpg',
        },
        {
          ReadMore: 'और पढ़ें',
          title: 'प्राचीन कलाकृतियाँ',
          description: "यहाँ दर्शकों को प्रथम बार अनावृत की जा रही 8वीं और 9वीं शताब्दी ईस्वी के गुर्जर-प्रतिहार काल की अद्वितीय मूर्तियों के दर्शन का विशेष अवसर प्राप्त होता है। ",
          image: '/images/block-c/block-c-image-3.jpg',
        },
      ],
      BlockD: [
        {
          ReadMore: 'और पढ़ें',
          title: 'अश्वत्थामा',
          description: "आपके समक्ष है अश्वत्थामा की एक विशालकाय यथार्थवादी प्रतिमा।  महाभारत युद्ध के अंतिम दिन द्रोण के मरने के पश्चात, सूर्यास्त के बाद  , जब पांडवों की सेना गहरी नींद में थी तब क्रोध से भरे अश्वत्थामा ने पांडवों के पूरे वंश की हत्या कर दी और अभिमन्यु के अजन्मे बच्चे को मारने का प्रयास किया । उनका ये अनैतिक कृत्य युद्ध के नियमों के खिलाफ था।  ",
          image: '/images/block-d/block-d-image-1.jpg',
        },
        {
          ReadMore: 'और पढ़ें',
          title: 'धृतराष्ट्र की पकड़',
          description: "एक होलोग्राफिक स्क्रीन पर प्रक्षेपण प्रतिशोध की हृदयस्पर्शी कथा प्रस्तुत करता है। इसमें धृतराष्ट्र के दरबार की सावधानीपूर्वक पुनर्रचित प्रतिकृति प्रदर्शित की गई है, जहाँ अपने पुत्रों के विनाश से शोकाकुल धृतराष्ट्र भीम के विरुद्ध प्रतिशोध लेने का प्रयास करते हैं। ",
          image: '/images/block-d/block-d-image-2.jpg',
        },
        {
          ReadMore: 'और पढ़ें',
          title: 'स्वर्गलोक',
          description: "यह वो मार्ग हैं जो आपको भव्य हिमालय की गोद में ले जाता है, वह मार्ग जिस पर पांडवों के पावन कदम भी पड़े थे । कुरुक्षेत्र के युद्ध के बाद, कई वर्षों तक हस्तिनापुर पर शासन कर, जब पांडवों को लगा कि उनके धर्म के सभी कर्तव्य पूरे हो गए हैं, तब उन्होंने सांसारिक जीवन त्यागने और स्वर्गलोक की यात्रा करने का निर्णय लिया। पांडवों ने हिमालय की ओर प्रस्थान किया, जो स्वर्गलोक का प्रवेश द्वार माना जाता है। यात्रा के दौरान, एक-एक करके द्रौपदी और चारों भाई (भीम, अर्जुन, नकुल, सहदेव) मृत्यु को प्राप्त हुए। केवल युधिष्ठिर ही अंत तक अडिग रहे और अपने सत्य और धर्म के कारण ही वे जीवित स्वर्गलोक पहुँच सके। ",
          image: '/images/block-d/block-d-image-3.jpg',
        },
      ],
      BlockE: [
        {
          ReadMore: 'और पढ़ें',
          title: 'अर्जुन का रथ',
          description: "भगवद गीता के ज्ञान से आलोकित होइए, उसी रथ पर बैठकर, जिस पर अर्जुन सारथी भगवान श्री कृष्ण के संग बैठे थे । चार भव्य, अतिवास्तविक घोड़ों से सुसज्जित यह रथ, उस दिव्य क्षण को अत्यंत सुंदरता के साथ पुनः जीवंत करता है ",
          image: '/images/block-e/block-e-image-1.jpg',
        },
        {
          ReadMore: 'और पढ़ें',
          title: 'गीता का ज्ञान',
          description: "यह गीता पॉड्स ज्ञान का अप्रतिम खज़ाना है जहाँ आप जीवन से संबंधित अपने प्रश्नों के उत्तर गीता के पूजनीय श्लोकों के रूप में पा सकते हैं।",
          image: '/images/block-e/block-e-image-2.jpg',
        },
        {
          ReadMore: 'और पढ़ें',
          title: 'भगवान कृष्ण की दिव्य गूंज ',
          description: "हवा में लहराती हजारों घंटियों की एक विशालकाय और खूबसूरत स्थापना। इन घंटियों के समागम से एक दिव्य चित्र उभर कर आती है, जिसमें भगवान कृष्ण बांसुरी बजाते हुए दिखाई देते हैं ।  घंटियों के स्वर की मिठास और कृष्ण की बांसुरी बजाते हुए इस चित्र में ब्रह्मांडिक समानता, दिव्य प्रेम, और आंतरिक शांति शामिल हैं, जो मानसिक और शारीरिक स्वास्थ्य पर सकारात्मक प्रभाव डालती हैं।",
          image: '/images/block-e/block-e-image-3.jpg',
        },
      ],
      // Other block exhibits here for hi
    },
  },
};




const Block = ({ blockLabel, exhibits, onExhibitClick, textContent }) => (
  <div className={`item ${blockLabel.toLowerCase().replace(/\s/g, '-')}`}>
    <div className="block-label">{blockLabel}</div>
    <div className="items-row">
      {exhibits.map((exhibit, index) => (
        <div className="exhibits-item" key={index}>
          <span className="frame-border">
            <img src="/images/block-card-border.png" alt="" />
          </span>
          <div className="exhibits-inner">
            <div className="exhibits-image">
              <img src={exhibit.image} alt={exhibit.title} />
            </div>
            <div className="exhibits-info">
              <h4>{exhibit.title}</h4>
              <p>{exhibit.description}</p>
              <div
                onClick={() => onExhibitClick(exhibit)}
                className="custom-globle-button"
              >
                {exhibit.ReadMore} {' '}
                <span>
                  <img
                    alt="MuseumExhibits-2"
                    src="data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iNDIiIGhlaWdodD0iMTYiIHZpZXdCb3g9IjAgMCA0MiAxNiIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZD0iTTAuODQ4Mjg1IDhMNDAuNjE2MSA4TTQwLjYxNjEgOEwzMS41MjYzIDFNNDAuNjE2MSA4TDMxLjUyNjMgMTUiIHN0cm9rZT0iIzNBM0EzQSIgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIiBzdHJva2UtbGluZWpvaW49InJvdW5kIi8+Cjwvc3ZnPgo="
                  />
                </span>
              </div>
            </div>
          </div>
        </div>
      ))}
    </div>
  </div>
);

const MuseumExhibits = ({ language }) => {  

  const [show, setShow] = useState(false);
  const [selectedExhibit, setSelectedExhibit] = useState(null);

  const handleClose = () => setShow(false);
  const handleExhibitClick = (exhibit) => {
    setSelectedExhibit(exhibit);
    setShow(true);
  };

  const exhibitsOptions = {
    loop: false,
    margin: 60,
    nav: true,
    dots: false,
    responsive: {
      0: { items: 1 },
      600: { items: 1 },
      1000: { items: 1 },
    },
  };

  const blocks = Object.keys(textContent[language].exhibits).map((key) => ({
    blockLabel: textContent[language][key],
    exhibits: textContent[language].exhibits[key],
  }));

  return (
    <>
      <div
        className="Museum-Exhibits"
        style={{ backgroundImage: 'url(/images/gradient-bg.svg)' }}
      >
        <div className="section-heading">
          <h2>{textContent[language].MainAttractionsHeading}</h2>
        </div>

        <div className="container with-custom-arrow">
          <OwlCarousel className="owl-theme" {...exhibitsOptions} key={language}>
            {blocks.map((block, index) => (
              <Block
                key={index}
                blockLabel={block.blockLabel}
                exhibits={block.exhibits}
                onExhibitClick={handleExhibitClick}
              />
            ))}
          </OwlCarousel>
        </div>
      </div>

      <Modal
        show={show}
        onHide={handleClose}
        centered
        size="lg"
        className="main_attraction_modal_detail"
      >
        {selectedExhibit && (
          <>
            <Modal.Header closeButton>
              <Modal.Title>{textContent[language].MainAttractionsHeading}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <div className="exhibits-image">
                <img src={selectedExhibit.image} alt={selectedExhibit.title} />
              </div>
              <div className="exhibits-info">
                <h4 className="exhibits-heading">{selectedExhibit.title}</h4>
                <p className="exhibits_content">{selectedExhibit.description}</p>
              </div>
            </Modal.Body>
          </>
        )}
      </Modal>
    </>
  );
};

export default MuseumExhibits;
