import React from 'react';

function Blockd({ language }) {
  // Content for both English and Hindi
  const content = {
    en: [
      {
        title: "Ashwatthama",
        image: "images/main-attractions/block-d/block-d-1.png",
        description: `Before you stands a colossal and highly realistic statue of Ashwatthama. On the final day of the Mahabharata war, after the death of Drona and after sunset, a furious Ashwatthama, driven by rage, massacred the entire lineage of the Pandavas while their army was in deep slumber. He even attempted to kill Abhimanyu's unborn child. This unethical act was a blatant violation of the rules of war. `,
        label: "Main Attraction",
      },
      {
        title: "Dhritirashtra’s vengeance",
        image: "images/main-attractions/block-d/block-d-2.png",
        description: `A projection on a holographic screen in the next zone unfolds a heartful tale of revenge. Shown by a meticulously recreated replica of Dhritarashtra's court, where the grief-stricken king mourning the loss of his sons, seeks vengeance against Bhima. `,
        label: "Main Attraction",
      },
      {
        title: "Yudhishtra’s Coronation ",
        image: "images/main-attractions/block-d/block-d-3.png",
        description: `After the devastating war of Kurukshetra, Yudhishtra's coronation took place. This is the grand court where Yudhishtra, regarded as a symbol of truth and righteousness, ascended the throne of Hastinapur. The royal palace of Hastinapur is adorned with flowers and lamps. This coronation symbolizes the triumph of truth and virtue over deceit and tyranny, marking the dawn of a new era of peace and prosperity for the world `,
        
      },
      {
        title: "Road to Heaven",
        image: "images/main-attractions/block-d/block-d-4.png",
        description: `This is the path that leads to the very lap of the majestic Himalayas, a route once traversed by the holy feet of the Pandavas. After the epic battle of Kurukshetra and years of ruling Hastinapur, the Pandavas, having fulfilled all their dharma duties, decided to renounce their worldly lives and embark on a journey to the heavens. They set out towards the Himalayas, believed to be the gateway to the divine realm. Along the way, one by one, Draupadi and the five brothers — Bhima, Arjuna, Nakul, Sahadeva, and Yudhishthira — met their end. Yet, Yudhishthira remained steadfast, surviving the journey until the very end. It was his unwavering commitment to truth and dharma that allowed him to reach the heavens alive. `,
        label: "Main Attraction",
      },
      {
        title: "Gandhari’s curse",
        image: "images/main-attractions/block-d/block-d-5.png",
        description: `After several years of their journey to the heavenly realms, the Pandavas' guide and Arjuna's charioteer, Krishna, faced a devastating curse. This was the curse of Gandhari, who, deeply saddened and enraged by the destruction of her sons, condemned Krishna. Standing amidst the bodies of her sons, Gandhari cursed Krishna, saying that just as her own lineage had been obliterated, so too would the Yadava dynasty be destroyed. Gandhari's curse turned into a prophecy, and soon a fierce civil war broke out within the Yadava clan, leading to the complete annihilation of Krishna's entire lineage. `,
      },
      {
        title: "Dwarka Submergence",
        image: "images/main-attractions/block-d/block-d-5.png",
        description: `As the profound impact of the curse takes hold, you are now entering the depths of the ocean. Here, you are witnessing the poignant moment of the submersion of Dwarka, the legendary city associated with Lord Krishna, as it sinks into the depths of the Arabian Sea. This event marks the end of an era, symbolizing the transition from the Dwapar Yuga to the Kali Yuga. `,
      },
      // Add other items here...
    ],
    hi: [
      {
        title: "अश्वत्थामा",
        image: "images/main-attractions/block-d/block-d-1.png",
        description: `आपके समक्ष है अश्वत्थामा की एक विशालकाय यथार्थवादी प्रतिमा।  महाभारत युद्ध के अंतिम दिन द्रोण के मरने के पश्चात, सूर्यास्त के बाद  , जब पांडवों की सेना गहरी नींद में थी तब क्रोध से भरे अश्वत्थामा ने पांडवों के पूरे वंश की हत्या कर दी और अभिमन्यु के अजन्मे बच्चे को मारने का प्रयास किया । उनका ये अनैतिक कृत्य युद्ध के नियमों के खिलाफ था। `,
        label: "मुख्य आकर्षण",
      },
      {
        title: "धृतराष्ट्र की पकड़",
        image: "images/main-attractions/block-d/block-d-2.png",
        description: `एक होलोग्राफिक स्क्रीन पर प्रक्षेपण प्रतिशोध की हृदयस्पर्शी कथा प्रस्तुत करता है। इसमें धृतराष्ट्र के दरबार की सावधानीपूर्वक पुनर्रचित प्रतिकृति प्रदर्शित की गई है, जहाँ अपने पुत्रों के विनाश से शोकाकुल धृतराष्ट्र भीम के विरुद्ध प्रतिशोध लेने का प्रयास करते हैं। `,
        label: "मुख्य आकर्षण",
      },
      {
        title: "युधिष्ठिर का राज्याभिषेक",
        image: "images/main-attractions/block-d/block-d-3.png",
        description: `कुरुक्षेत्र के विनाशकारी युद्ध के बाद युधिष्ठिर का राज्याभिषेक हुआ। यह वो भव्य दरबार है  जहां सत्य और धर्म के प्रतीक माने जाने वाले युधिष्ठिर  को हस्तिनापुर के सिंहासन पर बैठाया गया है । हस्तिनापुर के राजमहल को फूलों और दीपों से सजाया गया है । यह राज्याभिषेक सत्य और सद्गुण की छल और अत्याचार पर विजय का प्रतीक है, जो विश्व के लिए शांति और समृद्धि के नए युग का संकेत है।`,
        
      },
      {
        title: "स्वर्गलोक",
        image: "images/main-attractions/block-d/block-d-4.png",
        description: `यह वो मार्ग हैं जो आपको भव्य हिमालय की गोद में ले जाता है, वह मार्ग जिस पर पांडवों के पावन कदम भी पड़े थे । कुरुक्षेत्र के युद्ध के बाद, कई वर्षों तक हस्तिनापुर पर शासन कर, जब पांडवों को लगा कि उनके धर्म के सभी कर्तव्य पूरे हो गए हैं, तब उन्होंने सांसारिक जीवन त्यागने और स्वर्गलोक की यात्रा करने का निर्णय लिया। पांडवों ने हिमालय की ओर प्रस्थान किया, जो स्वर्गलोक का प्रवेश द्वार माना जाता है। यात्रा के दौरान, एक-एक करके द्रौपदी और चारों भाई (भीम, अर्जुन, नकुल, सहदेव) मृत्यु को प्राप्त हुए। केवल युधिष्ठिर ही अंत तक अडिग रहे और अपने सत्य और धर्म के कारण ही वे जीवित स्वर्गलोक पहुँच सके। `,
        label: "मुख्य आकर्षण",
      },
      {
        title: "गांधारी का श्राप",
        image: "images/main-attractions/block-d/block-d-5.png",
        description: `स्वर्गलोक यात्रा के कुछ सालों के उपरांत पांडवों के मार्गदर्शक, अर्जुन के सारथी, कृष्ण को एक विध्वंसकारक श्राप का सामना करना पड़ा।  अपने पुत्रों के विनाश के कारण अत्यंत दुखी और क्रोधित गांधारी का श्राप। अपने पुत्रों के शवों के बीच खड़ी, गांधारी ने कृष्ण को श्राप दिया कि जिस प्रकार उनके पुत्रों का वंश समाप्त हो गया, उसी प्रकार यादवों का वंश भी नष्ट हो जाएगा। गांधारी का यह श्राप भविष्यवाणी के रूप में बदल गया और यादव वंश में भीषण गृहयुद्ध हुआ, जिसमें कृष्ण के समस्त यादव वंश का विनाश हो गया। `,
      },
      {
        title: "द्वारका जलमग्न",
        image: "images/main-attractions/block-d/block-d-5.png",
        description: `श्राप के प्रकोप के गहरे प्रभाव से अब आप पानी की गेहराई में प्रवेश कर रहें हैं।  आप यहाँ भगवान कृष्ण से जुड़ी पौराणिक नगरी द्वारका के डूबने के मार्मिक क्षण को देख रहें है , जब वो अरब सागर की गहराई में विलीन हुआ । यह घटना एक युग के समापन और द्वापर युग से कलियुग में बदलाव का प्रतीक है।`,
      },
      // Add other items here...
    ],
  };

  return (
    <div className="main_attractions_list">
      {content[language].map((item, index) => (
        <div className="list_item" key={index}>
          <div className="attraction_image">
            <img src={item.image} alt={item.title} />
          </div>
          <div className="attranction_details">
            <h3>{item.title}</h3>
            <p>{item.description}</p>
            {item.label && <span className="main-attraction-label">{item.label}</span>}
          </div>
        </div>
      ))}
    </div>
  );
}

export default Blockd;
