import React from 'react';

function Blocke({ language }) {
  // Content for both English and Hindi
  const content = {
    en: [
      {
        title: "ARJUNA’S CHARIOT",
        image: "images/main-attractions/block-e/block-e-1.png",
        description: `Get enlightened by the wisdom of the Gita by sitting in that same chariot where Arjuna sat with Krishna as his charioteer. A chariot with four majestic hyper-realistic horses beautifully recreates that divine moment. `,
        label: "Main Attraction",
      },
      {
        title: "The Divine Resonance of Krishna",
        image: "images/main-attractions/block-e/block-e-2.png",
        description: `A massive and beautiful installation of thousands of bells swaying in the air. The convergence of these bells creates a divine image, where Lord Krishna is depicted playing his flute. The sweetness of the bell sounds and the image of Krishna playing the flute embody cosmic harmony, divine love, and inner peace, all of which have a positive impact on mental and physical well-being `,
        label: "Main Attraction",
      },
      {
        title: "The wisdom of Gita ",
        image: "images/main-attractions/block-e/block-e-3.png",
        description: `These Gita Pods are an unparalleled treasure of wisdom, where you can find answers to your life’s questions in the revered verses of the Gita. `,
        label: "Main Attraction",
      },
      {
        title: "VASUDEV KRISHNA ",
        image: "images/main-attractions/block-e/block-e-4.png",
        description: `Receive blessings from the divine holographic image of Lord Krishna`,
      },
      {
        title: "18 Chapters ",
        image: "images/main-attractions/block-e/block-e-5.png",
        description: `The interactive philosophy of the 18 chapters of the Mahabharata can be experienced in this space through its verses. It offers you the opportunity to immerse yourself in the profound symbolism and timeless wisdom embedded within this epic. `,
      },
      // Add other items here...
    ],
    hi: [
      {
        title: "अर्जुन का रथ",
        image: "images/main-attractions/block-e/block-e-1.png",
        description: `भगवद गीता के ज्ञान से आलोकित होइए, उसी रथ पर बैठकर, जिस पर अर्जुन सारथी भगवान श्री कृष्ण के संग बैठे थे । चार भव्य, अतिवास्तविक घोड़ों से सुसज्जित यह रथ, उस दिव्य क्षण को अत्यंत सुंदरता के साथ पुनः जीवंत करता है`,
        label: "मुख्य आकर्षण",
      },
      {
        title: "भगवान कृष्ण की दिव्य गूंज ",
        image: "images/main-attractions/block-e/block-e-2.png",
        description: `हवा में लहराती हजारों घंटियों की एक विशालकाय और खूबसूरत स्थापना। इन घंटियों के समागम से एक दिव्य चित्र उभर कर आती है, जिसमें भगवान कृष्ण बांसुरी बजाते हुए दिखाई देते हैं ।  घंटियों के स्वर की मिठास और कृष्ण की बांसुरी बजाते हुए इस चित्र में ब्रह्मांडिक समानता, दिव्य प्रेम, और आंतरिक शांति शामिल हैं, जो मानसिक और शारीरिक स्वास्थ्य पर सकारात्मक प्रभाव डालती हैं।  `,
        label: "मुख्य आकर्षण",
      },
      {
        title: "गीता का ज्ञान",
        image: "images/main-attractions/block-e/block-e-3.png",
        description: `यह गीता पॉड्स ज्ञान का अप्रतिम खज़ाना है जहाँ आप जीवन से संबंधित अपने प्रश्नों के उत्तर गीता के पूजनीय श्लोकों के रूप में पा सकते हैं। `,
        label: "मुख्य आकर्षण",
      },
      {
        title: "वासुदेव कृष्ण",
        image: "images/main-attractions/block-e/block-e-4.png",
        description: `भगवान श्री कृष्ण की दिव्य होलोग्राफिक छवि से आशीर्वाद प्राप्त करें `,
      },
      {
        title: "18 अध्याय ",
        image: "images/main-attractions/block-e/block-e-5.png",
        description: `महाभारत के 18 अध्याय का संवादात्मक दर्शन श्लोकों के माध्यम से इस क्षेत्र में अनुभव किया जा सकता है। यह महाकाव्य के भीतर निहित गहन प्रतीकवाद और कालातीत ज्ञान में आपको समाहित होने का अवसर प्रदान करता है।  `,
      },
      // Add other items here...
    ],
  };

  return (
    <div className="main_attractions_list">
      {content[language].map((item, index) => (
        <div className="list_item" key={index}>
          <div className="attraction_image">
            <img src={item.image} alt={item.title} />
          </div>
          <div className="attranction_details">
            <h3>{item.title}</h3>
            <p>{item.description}</p>
            {item.label && <span className="main-attraction-label">{item.label}</span>}
          </div>
        </div>
      ))}
    </div>
  );
}

export default Blocke;
