import React from 'react';

function Blocka({ language }) {
  // Content for both English and Hindi
  const content = {
    en: [
      {
        title: "RECEPTION",
        image: "images/main-attractions/block-a/block-a-1.png",
        description: `Behold the imposing Sudarshana Chakra.  This 8-ton Chakra is suspended here from the ceiling to enchant you with its cosmic presence. Crafted with exquisite details by skilled artisans from West Bengal, it is adorned with thousands of meticulously placed stones and gems, offering you a glimpse of its celestial power. Complementing this spectacle is a backdrop of cascading water that projects scenes from the Mahabharata, enhancing the overall experience. `,
        label: "Main Attraction",
      },
      {
        title: "Birth of an Epic",
        image: "images/main-attractions/block-a/block-a-2.png",
        description: `The briefing area features a remarkable projection depicting a dialogue  between Ved Vyasa and Lord Brahma, focusing on the collection of Vedic knowledge and its dissemination through the creation of the invaluable epic, the Mahabharata.  In this divine discourse, Lord Ganesha assumes a pivotal role as the scribe, transcribing the epic as narrated by Ved Vyasa. `,
      },
      {
        title: "Ancient Mahabharat",
        image: "images/main-attractions/block-a/block-a-3.png",
        description: `This area holds a map of Bharatavarsha, strategically positioned amidst the battlefield of Kurukshetra. The map highlights the alliances formed by different Janapadas with either the Kauravas or the Pandavas during the epic Mahabharata war. Adjacent to this stands a captivating reproduction of a 700-year-old Mahabharata manuscript, gracefully displayed on a pedestal. Accompanying this centrepiece are replicas of manuscripts scripted in the ancient Spitzer script, currently housed in the British Museum. `,
        label: "Main Attraction",
      },
      {
        title: "The History of Kuruvansh ",
        image: "images/main-attractions/block-a/block-a-4.png",
        description: `This mural, crafted by artists from Kolkata, depicts a captivating glimpse of Rishi Ved Vyasa recounting the Mahabharata. To add depth to the portrayal, the lineage of the Chandravanshi rulers is showcased, tracing their ancestry back to Lord Brahma through King Buddha, often referred to as the son of Soma or Chandra (the moon). It is through this lineage that the Kuru dynasty originated. `,
      },
      {
        title: "Geeta Shloka",
        image: "images/main-attractions/block-a/block-a-5.png",
        description: `This panel presents the most renowned Shloka from the Mahabharata. This verse stems from a conversation between Lord Krishna and Arjun amidst the great war of the Mahabharata, when Arjun hesitated to fight due to the presence of his loved ones on the opposing side. Krishna imparts the wisdom that one should not be driven by the desire for results, but rather focus on fulfilling their duties without attachment to the outcome. `,
      },
      {
        title: "Kuru Dynasty",
        image: "images/main-attractions/block-a/block-a-6.png",
        description: `Here you will witness the lineage of the Kuru dynasty. Beginning with Ganga's son, Bheeshma, it traces the lineage through Dhritarashtra, Pandu, and their subsequent heirs. It showcases the succession dispute and jealousy between the brothers, magnified by the ambitions of their sons, ultimately leading to the cataclysmic war of Mahabharata. `,
      },
      {
        title: "48 Kos",
        image: "images/main-attractions/block-a/block-a-7.png",
        description: `This installation captures the essence of the 48 kos yatra of the Kurukshetra region, encompassing 18 temples and 4 Yakshas within the modern boundaries of Haryana. This area is designed to offer a comprehensive experience, combining elements such as an installation, a projection film, two murals depicting Yaksha and Yakshini crafted by artists from Kolkata, and an informative kiosk for you to gain a profound understanding of the narrative. `,
        label: "Main Attraction",
      },
      {
        title: "The role of Krishna",
        image: "images/main-attractions/block-a/block-a-8.png",
        description: `This alluring projection screen presents a film where Lord Krishna serves as the narrator, guiding you through the fascinating journey of the creation of the renowned Mahabharata, the 48 kos yatra, and his manifestation on the Kuru Bhumi.  Watching this screen immerses you in the teachings and stories shared by Sri Krishna, captivating you in a mesmerizing experience. `,
      },
      {
        title: "Dashavtar",
        image: "images/main-attractions/block-a/block-a-9.png",
        description: `This ramp here is embellished with suspended panels portraying the Dasha Avatar of Lord Vishnu, referring to the ten incarnations of the supreme deity in Hinduism, symbolizing his periodic descents to Earth  to maintain harmony in the universe.`,
      },
      // Add other items here...
    ],
    hi: [
      {
        title: "स्वागत कक्ष",
        image: "images/main-attractions/block-a/block-a-1.png",
        description: `यहाँ आपके समक्ष है भव्य सदुर्शन चक्र।  अपनी ब्रह्मांडीय उपस्थिति से आपको आश्चर्यचकित करने के लिए आठ टन भारी इस चक्र को यहाँ छत से निलंबित किया गया है। पश्चिम बंगाल के कुशल कारीगरों द्वारा उत्कृष्ट विवरणों के साथ निर्मित यह हज़ारों  सावधानीपर्वूक रखे गए रत्नों से सुशोभित है, जो आपको इसकी दिव्य शक्ति की एक झलक प्रदान करता है।  । इस दृश्य में बहते पानी की पृष्ठभूमि भी शामिल है, जो महाभारत के दृश्यों को पेश करता है, जिस से समग्र अनभुव बढ़ जाता है। `,
        label: "मुख्य आकर्षण",
      },
      {
        title: "महाकाव्य का सृजन",
        image: "images/main-attractions/block-a/block-a-2.png",
        description: `इस चित्र में भगवान ब्रह्मा और वेद व्यास के मध्य होने वाला संवाद प्रदर्शित किया गया है। बातचीत का विषय वैदिक ज्ञान को इकठ्ठा करना और उसको दुनिया में फैलाने के लिए महाभारत जैसे  बहुमूल्य काव्य का निर्माण करना है। इस दिव्य प्रवचन में, भगवान गणेश लेखक के रूप में एक महत्वपूर्ण  भूमिका निभाते हैं और वेद व्यास द्वारा वर्णित महाकाव्य का प्रतिलेखन करते हैं।`,
      },
      {
        title: "प्राचीन महाभारत",
        image: "images/main-attractions/block-a/block-a-3.png",
        description: `इस पैनल में कुरुक्षेत्र के युद्ध के मैदान को दिखाया गया है जिसमें भारतवर्ष का मानचित्र है। इस मानचित्र में महाभारत के काल में कौरवों और पांडवों के साथ जिस जिस क्षेत्र का गठबधंन हुआ था उन पर रोशनी डाली गई है।  और इसी मानचित्र के साथ 700 साल प्राचीन महाभारत की प्रति को आसन पर सुंदर रूप से सजाया गया है।  इसी के साथ स्पिट्ज़र लिपि में लिखे दस्तावेज़ों की प्रतिकृतियां है जो वर्तमान में ब्रिटिश सग्रंहालय में रखी गई है। `,
        label: "मुख्य आकर्षण",
      },
      {
        title: "कुरुवंश का इतिहास",
        image: "images/main-attractions/block-a/block-a-4.png",
        description: `इस पैनल में कोलकाता के कलाकारों द्वारा बनाए गए भित्ति चित्र में ऋषि वेद व्यास जी की महाभारत वर्णन करते हुए सुंदर झलक दिखाई गई है। चित्रण में गहराई जोड़ने के लिए, चित्र में चद्रं वंशी शासकों की वंशावली को भी दिखाया गया है जो राजा बुद्ध के माध्यम से होते हुए भगवान ब्रह्मा के वशं का पता लगाते हैं जिन्हे अक्सर चद्रंमा का पुत्र कहा गया है और इसी से ही कुरु वशं की उत्पति हुई। `,
      },
      {
        title: "गीता श्लोक",
        image: "images/main-attractions/block-a/block-a-5.png",
        description: `यहाँ महाभारत के सबसे प्रसिद्ध श्लोक को दर्शाया गया है। यह श्लोक महाभारत के युद्ध के समय भगवान कृष्ण और अर्जुन के बीच हुई बातचीत से ही उत्पन्न हुआ । जब अर्जुन विरोधी पक्ष में अपने परिवारजनों के होने के कारण लड़ने से झिझक रहे थे तब कृष्ण ज्ञान देते हैं कि किसी को परिणाम यानि फल की इच्छा के बिना अपने कर्तव्यों को पूरा करने पर ध्यान देना चाहिए। `,
      },
      {
        title: "कुरु वंशावली",
        image: "images/main-attractions/block-a/block-a-6.png",
        description: `यहाँ कुरु राजवशं की वंशावली को प्रदर्शित किया गया है। गंगा के पुत्र भीष्म से शरू होकर, यह धतृराष्ट्र, पांडु और उनके बाद के उत्तराधिकारियों के माध्यम से आगे बढ़ती है। यह भाइयों के बीच उत्तराधिकार विवाद और ईर्ष्या को दर्शाता है, जो उनके बेटों की महत्वाकांक्षाओं से बढ़ जाता है, जो अतंतः महाभारत के विनाशकारी युद्ध  की ओर ले जाता है।`,
      },
      {
        title: "48 कोस",
        image: "images/main-attractions/block-a/block-a-7.png",
        description: `यह स्थापना कुरुक्षेत्र क्षेत्र की 48 कोस यात्रा के सार को दर्शाती है, जिसमें हरियाणा की आधनिुनिक सीमाओं के भीतर 18 मंदिर  और 4 यक्ष शामिल हैं। इस क्षेत्र को एक व्यापक अनभुव प्रदान करने के लिए बनाया गया है, जिसमें एक त्रिआयामी चित्र, एक प्रोजेक्शन फिल्म, कोलकाता के कलाकारों द्वारा तैयार किए गए यक्ष और यक्षिनी को दर्शाने वाले दो भित्ति चित्र और कथा की गहरी समझ प्राप्त करने के लिए एक सूचनात्मक कियोस्क जसै तत्वों का संयोजन  किया गया है।`,
        label: "मुख्य आकर्षण",
      },
      {
        title: "कृष्ण भूमिका",
        image: "images/main-attractions/block-a/block-a-8.png",
        description: `यह आकर्षक प्रोजेक्शन स्क्रीन एक फिल्म परतूत करती है जहाँ भगवान कृष्ण वाचक के रूप में आपको महाभारत की रचना की रोमांचक यात्रा, 48 कोस यात्रा, और कुरु भूमि पर उनके अवतरण के विषय में मार्गदर्शन करते हैं।   यहाँ आप श्री कृष्ण द्वारा साझा की गई शिक्षाओं और कहानियों में डूब जाते हैं, जो आपको एक मंत्रमुग्ध कर देने वाले अनुभव की ओर ले जाती है।`,
      },
      {
        title: "दशवतार",
        image: "images/main-attractions/block-a/block-a-9.png",
        description: `यह स्थल भगवान विष्णु के दशवतार को चित्रित करने वाले निलंबित पैनल से अलंकृत है, जो हिन्दू धर्म में सर्वोच्च देवता के दस अवतारों का विवरण करता है। भगवान विष्णु के यह अवतार ब्रह्मांड में सद्भाव बनाए रखने के लिए पृथ्वी पर उनके आवधिक अवतरण का प्रतीक हैं।  `,
      },
    ],
  };

  return (
    <div className="main_attractions_list">
      {content[language].map((item, index) => (
        <div className="list_item" key={index}>
          <div className="attraction_image">
            <img src={item.image} alt={item.title} />
          </div>
          <div className="attranction_details">
            <h3>{item.title}</h3>
            <p>{item.description}</p>
            {item.label && <span className="main-attraction-label">{item.label}</span>}
          </div>
        </div>
      ))}
    </div>
  );
}

export default Blocka;
