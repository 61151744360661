import React from 'react';

function Blockbc({ language }) {
  // Content for both English and Hindi
  const content = {
    en: [
      {
        title: "Pandavas' Exile",
        image: "images/main-attractions/block-bc/block-bc-1.png",
        description: `Before you unfolds the chapter of the Mahabharata, where the devious schemes of Duryodhana and Shakuni forced the Pandavas into a challenging journey of twelve years of exile followed by a thirteenth year in disguise. During this exile, every challenge posed by nature severely tested their patience and courage. In these divine periods of exile, they were blessed with the company of sages and ascetics who enlightened them with spiritual teachings on righteousness, ethics, and the profound truths of life. This relentless experience fortified them further. Upon returning from exile, the Pandavas emerged stronger and more capable. `,
      },
      {
        title: "Krishna’s alliance",
        image: "images/main-attractions/block-bc/block-bc-2.png",
        description: `After completing their exile and incognito period, the Pandavas demanded the return of their rightful kingdom, but Duryodhana refused. Lord Krishna, as a peace envoy, arrived in Hastinapur and proposed a settlement of just five villages. However, Duryodhana arrogantly declined, refusing to part with even a needlepoint of land. At that moment, Lord Krishna revealed his cosmic form, radiating countless flames and embodying the boundless energy of the universe. He declared that the time for pleas had ended. Destruction was inevitable, and the consequences of one's actions were certain. The only remaining path was a fierce and decisive war. `,
      },
      {
        title: "The battle of Kurukshetra ",
        image: "images/main-attractions/block-bc/block-bc-3.png",
        description: `Under a magnificent dome, witness the vivid and dynamic scenes of the great Kurukshetra War. This unique platform, equipped with 360-degree rotation, offers viewers an immersive experience of the eighteen-day epic battle of the Mahabharata, where virtue clashes with vice, ultimately leading to the triumph of righteousness over betrayal. With the fulfillment of divine prophecies and celestial plans, this grand war symbolizes the conclusion of the Dvapara Yuga and the dawn of the Kaliyuga `,
      },
      // Add other items here...
    ],
    hi: [
      {
        title: "पांडव वनवास",
        image: "images/main-attractions/block-bc/block-bc-1.png",
        description: `आपके समक्ष महाभारत का वह अध्याय प्रस्तुत है, जब दुर्योधन और शकुनि की कुटिल चाल ने पांडवों को 12 वर्षों के वनवास और 13वें वर्ष के अज्ञातवास की कठिन यात्रा पर जाने के लिए विवश किया।इस वनवास के दौरान, प्रकृति की हर चुनौती ने उनके धैर्य और साहस की कठोर परीक्षा ली। वनवास के इन दिव्य कालखंडों में, उन्हें ऋषि-मुनियों एवं संतों का सान्निध्य प्राप्त हुआ, जिन्होंने उन्हें धर्म, नीति एवं जीवन के गूढ़ तत्वों की आध्यात्मिक शिक्षाओं से अभिसिंचित किया। समय के इस अमानवीय अनुभव ने उन्हें और अधिक सशक्त बना दिया था। अज्ञातवास समाप्त कर लौटने पर, पांडव पहले से कहीं अधिक सक्षम और दृढ़ बन चुके थे। `,        
      },
      {
        title: "कृष्ण संधि",
        image: "images/main-attractions/block-bc/block-bc-2.png",
        description: `वनवास और अज्ञातवास पूर्ण होने के बाद पांडवों ने अपना राज्य वापस मांगा, लेकिन दुर्योधन ने इसे ठुकरा दिया। श्रीकृष्ण शांतिदूत बनकर हस्तिनापुर पहुँचे और केवल पाँच ग्राम का प्रस्ताव रखा, किंतु दुर्योधन ने सुई की नोंक बराबर भूमि देने से भी इनकार कर दिया। तब श्रीकृष्ण ने विराट रूप धारण किया, जिसमें अनगिनत अग्निज्वालाएँ प्रज्वलित थीं और ब्रह्मांड उनकी ऊर्जा में समाहित था। उन्होंने स्पष्ट किया कि अब याचना का समय समाप्त हो चुका है। विनाश अवश्यंभावी था और कर्मों का दंड निश्चित था। अब केवल भीषण युद्ध ही अंतिम विकल्प था। `,
      },
      {
        title: "कुरुक्षेत्र का युद्ध",
        image: "images/main-attractions/block-bc/block-bc-3.png",
        description: `यहाँ एक भव्य गुंबद के नीचे कुरुक्षेत्र के महायुद्ध के सजीव दृश्य अवलोकन करें। 360-डिग्री रोटेशन से युक्त यह विशिष्ट आसन दर्शकों को महाभारत के अठारह दिवसीय संग्राम का गहन अनुभव प्रदान करती है। जहाँ पुण्य एवं पाप का टकराव होता है और अंततः विश्वासघात पर धर्म की विजय स्थापित होती है। दिव्य भविष्यवाणियों एवं दैविक योजनाओं की पूर्णता के साथ यह महासंग्राम द्वापर युग के अवसान तथा कलियुग के उदय का प्रतीक बनकर उभरता है। `,
      },
      // Add other items here...
    ],
  };

  return (
    <div className="main_attractions_list">
      {content[language].map((item, index) => (
        <div className="list_item" key={index}>
          <div className="attraction_image">
            <img src={item.image} alt={item.title} />
          </div>
          <div className="attranction_details">
            <h3>{item.title}</h3>
            <p>{item.description}</p>
            {item.label && <span className="main-attraction-label">{item.label}</span>}
          </div>
        </div>
      ))}
    </div>
  );
}

export default Blockbc;
