import React from 'react';

function Blockc({ language }) {
  // Content for both English and Hindi
  const content = {
    en: [
      {
        title: "Gandiva",
        image: "images/main-attractions/block-c/block-c-1.png",
        description: `This captivating panel depicts Lord Varuna, Arjuna, and his renowned Gandiva bow, highlighting the remarkable prowess of a warrior and his weapons. The Gandiva stands as a symbol of righteousness and morality `,
      },
      {
        title: "9 highlights of war ",
        image: "images/main-attractions/block-c/block-c-2.png",
        description: `Nine LED screens introduce the lesser-known narratives of the Mahabharata, which, significantly impacted the overall story. The audience moves from the lesser known  story of Barbaric , to Raja Shalya, Arjunas chariot and many others. `,
        label: "Main Attraction",
      },
      {
        title: "BHEESHMA’S BED OF ARROWS",
        image: "images/main-attractions/block-c/block-c-3.png",
        description: `This zone enthrals you with a spellbinding Kinetic installation of Bheeshma shayya with a hyper-realistic sculpture of Bheeshma being on the shaiya of arrows and hundreds of specially crafted arrows dynamically moving through a kinetic installation.   `,
        label: "Main Attraction",
      },
      {
        title: "DATING OF MAHABHARATA",
        image: "images/main-attractions/block-c/block-c-4.png",
        description: `A dome projection which offers evidence of  Mahabharata's historical accuracy by analysing astronomical references like eclipses and planetary alignments.`,
      },
      {
        title: "ANCIENT ARTIFACTS ",
        image: "images/main-attractions/block-c/block-c-5.png",
        description: `Here the viewer gets the privilege of seeing sculptures from the Gurjara-Pratihara period, dating back to the 8th and 9th centuries CE which have never been unveiled before.   `,
        label: "Main Attraction",
      },
      // Add other items here...
    ],
    hi: [
      {
        title: "गांडीव",
        image: "images/main-attractions/block-c/block-c-1.png",
        description: `यह आकर्षक पैनल वरुण देव, अर्जुन और उनके प्रसिद्ध गांडीव धनुष की चित्रणों को दर्शाता है, और एक योद्धा और उसके शस्त्रों की अद्भुत क्षमता को उजागर करता है। यह गांडीव, धर्म और नैतिकता का प्रतीक है। `,        
      },
      {
        title: "युद्ध के 9 प्रमुख दृष्टांत",
        image: "images/main-attractions/block-c/block-c-2.png",
        description: `नौ एलईडी स्क्रीन महाभारत की उन कम प्रचलित कथाओं का परिचय कराती हैं, जिन्होंने सम्पूर्ण महाकाव्य की संरचना पर गहन प्रभाव डाला। दर्शक बर्बरिक की अप्रसिद्ध कथा से लेकर राजा शल्य, अर्जुन के रथ और अन्य अनेक रोचक प्रसंगों की ओर अग्रसर होते हैं`,
        label: "मुख्य आकर्षण",
      },
      {
        title: "भीष्म शरशय्या",
        image: "images/main-attractions/block-c/block-c-3.png",
        description: `यह  क्षेत्र आपको भीष्म-शैया की एक मंत्रमुग्ध कर देने वाली गतिशील स्थापना से रोमांचित कर देता है, जहाँ भीष्म की अत्यंत यथार्थवादी मूर्ति उन्हें बाणों की शैया पर स्थित दर्शाती है। सैकड़ों विशेष रूप से निर्मित बाण इस गतिशील स्थापना को अद्वितीय बनाते हैं `,
      },
      {
        title: "महाभारत का काल निर्धारण",
        image: "images/main-attractions/block-c/block-c-4.png",
        description: `एक गुंबदीय प्रक्षेपण, जो ग्रहणों और ग्रहीय संरेखण जैसे खगोलीय संदर्भों का विश्लेषण कर महाभारत की ऐतिहासिक प्रामाणिकता के प्रमाण प्रस्तुत करता है। `,
      },
      {
        title: "प्राचीन कलाकृतियाँ",
        image: "images/main-attractions/block-c/block-c-5.png",
        description: `यहाँ दर्शकों को प्रथम बार अनावृत की जा रही 8वीं और 9वीं शताब्दी ईस्वी के गुर्जर-प्रतिहार काल की अद्वितीय मूर्तियों के दर्शन का विशेष अवसर प्राप्त होता है।`,
        label: "मुख्य आकर्षण",
      },
      // Add other items here...
    ],
  };

  return (
    <div className="main_attractions_list">
      {content[language].map((item, index) => (
        <div className="list_item" key={index}>
          <div className="attraction_image">
            <img src={item.image} alt={item.title} />
          </div>
          <div className="attranction_details">
            <h3>{item.title}</h3>
            <p>{item.description}</p>
            {item.label && <span className="main-attraction-label">{item.label}</span>}
          </div>
        </div>
      ))}
    </div>
  );
}

export default Blockc;
